


































































import { defineComponent, computed, onUnmounted } from "@vue/composition-api";
import InvoiceAddressPage from "@/views/StickerPurchasePage/components/StickerPay/components/StickerPayBillingData/InvoiceAddressPage.vue";
import DialogService from "@/core/services/dialog.service";
import InvoiceAddressSelectorDialog from "@/components/dialog/InvoiceAddressSelectorDialog.vue";
import NewBillingAddressDialog from "@/components/dialog/NewBillingAddressDialog.vue";
import ModifyBillingAddressDialog from "@/components/dialog/ModifyBillingAddressDialog.vue";
import { stickerFacade } from "@/store/modules/sticker/sticker.facade";
import { userFacade } from "@/store/modules/user/user.facade";
import { isEmpty } from "lodash";

export default defineComponent({
  name: "StickerPayBillingData",
  components: {
    InvoiceAddressPage,
  },
  props: {
    loading: Boolean,
  },

  setup() {
    const notLoggedInUser = computed(userFacade.notLoggedInUser);
    const billingAddress = computed(stickerFacade.billingAddress);
    const billingAddressLoading = computed(stickerFacade.billingAddressLoading);
    const billingAddressListLoading = computed(
      stickerFacade.billingAddressListLoading
    );
    const addBillingAddress = () => {
      if (notLoggedInUser.value) {
        DialogService.open({
          maxWidth: 560,
          persistent: true,
          customClass: "fullscreen-dialog-on-xs",
          component: isEmpty(billingAddress.value)
            ? NewBillingAddressDialog
            : ModifyBillingAddressDialog,
        });
      } else {
        stickerFacade.getBillingAddressList().then(() => {
          DialogService.open({
            maxWidth: 424,
            persistent: true,
            customClass: "fullscreen-dialog-on-xs",
            component: InvoiceAddressSelectorDialog,
          });
        });
      }
    };
    onUnmounted(() => {
      DialogService.closeAll();
    });

    return {
      notLoggedInUser,
      billingAddress,
      billingAddressLoading,
      billingAddressListLoading,
      addBillingAddress,
      isEmpty,
    };
  },
});
