










































































































































































import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from "@vue/composition-api";
import router from "@/router";
import { ERoutes } from "@/core/dataTypes/enum";
import StickerCard from "@/views/StickerPurchasePage/components/StickerPay/components/StickerPayBasket/components/StickerCard/StickerCard.vue";
import { stickerFacade } from "@/store/modules/sticker/sticker.facade";
import SnackService from "@/core/services/snackbar.service";
import { i18n } from "@/locales/i18n";
import { isEmpty } from "lodash";
import { userFacade } from "@/store/modules/user/user.facade";
import { alertFacade } from "@/store/modules/alert/alert.facade";
import CheckboxInput from "@/components/forms/checkbox/CheckboxInput.vue";
import { getLanguageStaticRoute } from "@/core/utils/router.util";
import { commonFacade } from "@/store/modules/common/common.facade";

export default defineComponent({
  name: "StickerPayBasket",
  components: {
    CheckboxInput,
    StickerCard,
  },
  props: {
    loading: Boolean,
  },
  setup(props) {
    const aszfLink = computed(commonFacade.aszf);
    const dataProtectionLink = computed(commonFacade.dataProtection);
    const startCartTransactionLoading = computed(
      stickerFacade.startCartTransactionLoading
    );
    const notLoggedInUser = computed(userFacade.notLoggedInUser);
    const cartAdhocExtraDataIsValid = computed(
      stickerFacade.cartAdhocExtraDataIsValid
    );
    const billingAddress = computed(stickerFacade.billingAddress);
    const isValidPayment = computed(() => {
      const billingAddressIsValid = !isEmpty(billingAddress.value);
      return notLoggedInUser.value
        ? billingAddressIsValid &&
            cartAdhocExtraDataIsValid.value &&
            termsAndConditions.value &&
            dataManagementStatement.value
        : billingAddressIsValid;
    });

    const cartElementList = computed(stickerFacade.cartElementList);
    const finalCost = computed(stickerFacade.finalCost);
    const cartId = computed(stickerFacade.cartId);
    const termsAndConditions = ref(false);
    const dataManagementStatement = ref(false);
    const creditCardIcons = computed(commonFacade.creditCardsIcons);
    const creditCardsIconsLoading = computed(
      commonFacade.creditCardsIconsLoading
    );

    const addNewSticker = () => {
      router.push(
        getLanguageStaticRoute(
          `${ERoutes.STICKER_PURCHASE}/${ERoutes.STICKER_CREATION}`
        )
      );
    };

    const deleteCard = async (cartElementId: string) => {
      if (notLoggedInUser.value) {
        await stickerFacade.getAdHocValidityCheck(true);
      }
      await stickerFacade.deleteCartElement(cartElementId);
      if (!notLoggedInUser.value) {
        await stickerFacade.getActiveCart();
      } else {
        await stickerFacade.getAdHocValidityCheck();
      }
      if (cartId.value) {
        stickerFacade.getCart();
      } else {
        stickerFacade.resetCart();
        stickerFacade.resetAdhocExtraDataIsValid();
        router.push(getLanguageStaticRoute(ERoutes.EMPTY_CART));
      }
      SnackService.open({
        text: i18n.tc("STICKER_PAY_BASKET.SNACK_BAR_MESSAGE"),
      });
    };

    const onPayment = async () => {
      alertFacade.resetAlert();
      try {
        if (notLoggedInUser.value) {
          const isValidAdHocId = await stickerFacade.getAdHocValidityCheck(
            true
          );
          if (!isValidAdHocId) {
            return;
          }
        }
        await stickerFacade.putFinalizeInvoiceContact();
        await stickerFacade.startCartTransaction({
          cart: {
            cartId: cartId.value as string,
            invoiceSendingMode: "E",
          },
          frontEndURL: window.location.href,
          customerId: "",
        });
      } catch (e) {
        console.error(e);
      }
    };
    let cartDataInterval: number;

    const getAdhocValidation = () => {
      if (notLoggedInUser.value && !props.loading) {
        cartDataInterval = setInterval(() => {
          stickerFacade.getAdHocValidityCheck(true);
        }, 10000);
      } else {
        clearInterval(cartDataInterval);
      }
    };

    watch(
      () => props.loading,
      () => {
        getAdhocValidation();
      }
    );

    onMounted(() => {
      commonFacade.getCreditCardIcons();
      getAdhocValidation();
    });
    onUnmounted(() => {
      clearInterval(cartDataInterval);
    });
    return {
      billingAddress,
      notLoggedInUser,
      isEmpty,
      onPayment,
      addNewSticker,
      deleteCard,
      isValidPayment,
      cartElementList,
      finalCost,
      startCartTransactionLoading,
      termsAndConditions,
      dataManagementStatement,
      cartAdhocExtraDataIsValid,
      creditCardIcons,
      creditCardsIconsLoading,
      aszfLink,
      dataProtectionLink,
    };
  },
});
