






























import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
} from "@vue/composition-api";
import StickerPayBillingData from "@/views/StickerPurchasePage/components/StickerPay/components/StickerPayBillingData/StickerPayBillingData.vue";
import StickerPayEmailNotification from "@/views/StickerPurchasePage/components/StickerPay/components/StickerPayEmailNotification/StickerPayEmailNotification.vue";
import StickerPayBasket from "@/views/StickerPurchasePage/components/StickerPay/components/StickerPayBasket/StickerPayBasket.vue";
import { stickerFacade } from "@/store/modules/sticker/sticker.facade";
import { i18n } from "@/locales/i18n";

export default defineComponent({
  name: "StickerPay",
  components: {
    StickerPayBillingData,
    StickerPayEmailNotification,
    StickerPayBasket,
  },
  setup(props, { emit }) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let cancelPaymentTimeOut = null as any;
    const transactionId = computed(stickerFacade.transactionId);
    const startCartTransactionLoading = computed(
      stickerFacade.startCartTransactionLoading
    );
    const transactionsPaymentLoading = computed(
      stickerFacade.transactionsPaymentLoading
    );
    const cartStatus = computed(stickerFacade.cartStatus);
    const isPaymentProgress = computed(() => cartStatus.value === "6");

    const stickerPayCartData = async () => {
      await stickerFacade.getCart();
      await stickerFacade.getBillingAddress();
      if (transactionId.value) {
        await stickerFacade.getTransactions();
        cancelPaymentTimeOut = setTimeout(() => {
          stickerFacade.setTransactionsPaymentProgressLongTime(true);
        }, 15000);
      }
    };
    onMounted(() => {
      stickerPayCartData();
      emit("changeStep", 3);
    });
    onUnmounted(() => {
      clearTimeout(cancelPaymentTimeOut);
    });
    stickerFacade.setTransactionsPaymentProgressLongTime(false);
    return {
      isPaymentProgress,
      transactionsPaymentLoading,
      startCartTransactionLoading,
    };
  },
  metaInfo() {
    return {
      titleTemplate: `${i18n.tc("STICKER_PURCHASE_PAGE.PAGE_TITLE")} | %s`,
    };
  },
});
