











































import { defineComponent } from "@vue/composition-api";
import { isEmpty } from "lodash";
export default defineComponent({
  name: "InvoiceAddressPage",
  props: {
    address: Object,
    pkey: {
      type: String,
    },
    selectedAddressId: {
      type: String,
      default: "",
    },
    hasList: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const selectProfileCard = (): void => {
      emit("clickOnInvoiceAddress", props.address);
    };

    return {
      selectProfileCard,
      isEmpty,
    };
  },
});
