




























import { computed, defineComponent, reactive } from "@vue/composition-api";
import { dialogCloseEmitter } from "@/core/utils/dialog/dialogEmitter";
import BaseDialog from "@/components/dialog/baseDialog/BaseDialog.vue";
import { CreateAndModifyBillingAddressFormState } from "@/core/dataTypes/types";
import NewBillingDetailForm from "@/components/forms/NewBillingDetailsForm/NewBillingDetailsForm.vue";
import DialogService from "@/core/services/dialog.service";
import { alertFacade } from "@/store/modules/alert/alert.facade";
import { stickerFacade } from "@/store/modules/sticker/sticker.facade";
import { userFacade } from "@/store/modules/user/user.facade";

export default defineComponent({
  name: "NewInvoiceAddressDialog",
  components: {
    BaseDialog,
    NewBillingDetailForm,
  },
  setup(props, ctx) {
    const notLoggedInUser = computed(userFacade.notLoggedInUser);
    const billingAddress = computed(stickerFacade.billingAddress);
    const createBillingAddressLoading = computed(
      stickerFacade.createBillingAddressLoading
    );

    const formValues = reactive<
      Partial<CreateAndModifyBillingAddressFormState>
    >({
      corporation: 0,
      billingName: "",
      country: "",
      city: "",
      zipCode: undefined,
      street: "",
      streetNumber: "",
      streetType: "",
      floor: "",
      door: undefined,
      vatCountry: undefined,
      vatNumber: "",
      invoiceGroupId: "",
      euVatNumber: "",
      thirdCountryVatNumber: "",
      defaultBillingAddress: false,
      hasNotEUTaxNumber: false,
    });

    const closeDialog = () => {
      dialogCloseEmitter(ctx);
    };

    const submit = async () => {
      alertFacade.resetAlert();
      if (!notLoggedInUser.value) {
        await stickerFacade.createBillingAddress(formValues);
        await stickerFacade.putBillingAddress({
          billingAddressId: billingAddress.value?.customerAddressId as string,
        });
      } else {
        const isValidAdHocId = await stickerFacade.getAdHocValidityCheck(true);
        if (isValidAdHocId) {
          await stickerFacade.createBillingAddress(formValues);
        }
      }
      DialogService.closeAll();
    };

    return {
      formValues,
      notLoggedInUser,
      createBillingAddressLoading,
      submit,
      closeDialog,
    };
  },
});
