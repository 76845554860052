







































































import { computed, defineComponent, ref } from "@vue/composition-api";
import BaseDialog from "@/components/dialog/baseDialog/BaseDialog.vue";
import InvoiceAddressPage from "@/views/StickerPurchasePage/components/StickerPay/components/StickerPayBillingData/InvoiceAddressPage.vue";
import {
  AddressType,
  CreateBillingAddressRequestType,
} from "@/core/dataTypes/types";
import { dialogCloseEmitter } from "@/core/utils/dialog/dialogEmitter";
import { stickerFacade } from "@/store/modules/sticker/sticker.facade";
import DialogService from "@/core/services/dialog.service";
import NewBillingAddressDialog from "@/components/dialog/NewBillingAddressDialog.vue";
import CardSelector from "@/components/forms/CardSelector/CardSelector.vue";

export default defineComponent({
  name: "InvoiceAddressSelectorDialog",
  components: {
    BaseDialog,
    InvoiceAddressPage,
    CardSelector,
  },
  setup(props, ctx) {
    const billingAddressList = computed(stickerFacade.billingAddressList);
    const billingAddress = computed(stickerFacade.billingAddress);
    const maxBillingAddressNum = computed(stickerFacade.maxBillingAddressNum);
    const putBillingAddressLoading = computed(
      stickerFacade.putBillingAddressLoading
    );
    const selectedAddressId = ref("");
    const selectedBillingAddress = ref({});
    const clickOnInvoiceAddress = (
      billingAddress: Partial<AddressType>,
      selectId: string
    ) => {
      selectedAddressId.value = selectId;
      selectedBillingAddress.value = billingAddress;
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    billingAddressList.value?.forEach((item: any) => {
      if (
        item.billingAddress.customerAddressId ===
        billingAddress.value?.customerAddressId
      ) {
        clickOnInvoiceAddress(
          item.billingAddress,
          item.billingAddress.customerAddressId
        );
      }
    });

    const setActualInvoiceAddress = () => {
      stickerFacade
        .putBillingAddress({
          billingAddressId: selectedAddressId.value,
        })
        .then(() => {
          const billingAddressIndex = billingAddressList.value?.findIndex(
            (ba) => {
              const billingAddress = ba as CreateBillingAddressRequestType;
              return (
                billingAddress.billingAddress.customerAddressId ===
                selectedAddressId.value
              );
            }
          ) as number;
          const selectedBillingAddressList =
            billingAddressList.value as Partial<CreateBillingAddressRequestType>[];
          stickerFacade.setActualBillingAddress({
            billingAddress:
              selectedBillingAddressList[billingAddressIndex].billingAddress,
          });
          closeDialog();
        });
    };

    const closeDialog = () => {
      dialogCloseEmitter(ctx);
    };

    const addNewAddress = () => {
      DialogService.open({
        maxWidth: 560,
        persistent: true,
        customClass: "fullscreen-dialog-on-xs",
        component: NewBillingAddressDialog,
      });
    };

    return {
      billingAddressList,
      maxBillingAddressNum,
      selectedAddressId,
      selectedBillingAddress,
      setActualInvoiceAddress,
      clickOnInvoiceAddress,
      addNewAddress,
      closeDialog,
      putBillingAddressLoading,
    };
  },
});
