




























import { computed, defineComponent, reactive } from "@vue/composition-api";
import BaseDialog from "@/components/dialog/baseDialog/BaseDialog.vue";
import { CreateAndModifyBillingAddressFormState } from "@/core/dataTypes/types";
import { dialogCloseEmitter } from "@/core/utils/dialog/dialogEmitter";
import ModifyBillingDetailsForm from "@/components/forms/ModifyBillingDetailsForm/ModifyBillingDetailsForm.vue";
import { alertFacade } from "@/store/modules/alert/alert.facade";
import { stickerFacade } from "@/store/modules/sticker/sticker.facade";
import { userFacade } from "@/store/modules/user/user.facade";
import { commonFacade } from "@/store/modules/common/common.facade";

export default defineComponent({
  name: "ModifyInvoiceAddressDialog",
  components: {
    BaseDialog,
    ModifyBillingDetailsForm,
  },
  setup(props, ctx) {
    const notLoggedInUser = computed(userFacade.notLoggedInUser);
    const modifyBillingAddressLoading = computed(
      stickerFacade.modifyBillingAddressLoading
    );
    const billingAddress = computed(stickerFacade.billingAddress);
    const countries = computed(commonFacade.countries);

    const formValues = reactive<
      Partial<CreateAndModifyBillingAddressFormState>
    >({
      corporation: 0,
      billingName: "",
      country: "",
      city: "",
      zipCode: undefined,
      street: "",
      streetNumber: "",
      streetType: "",
      floor: "",
      door: undefined,
      vatCountry: undefined,
      vatNumber: "",
      invoiceGroupId: "",
      euVatNumber: "",
      thirdCountryVatNumber: "",
      defaultBillingAddress: false,
      hasNotEUTaxNumber: false,
    });

    const submit = async () => {
      alertFacade.resetAlert();
      const form = {
        ...billingAddress.value,
        ...formValues,
      } as Partial<CreateAndModifyBillingAddressFormState>;
      const isValidAdHocId = await stickerFacade.getAdHocValidityCheck(true);
      if (isValidAdHocId) {
        await stickerFacade.modifyBillingAddress(form).then(() => {
          const varibles = {
            ...form,
            country: countries.value?.find((val) => val.id === form.country)
              ?.name,
            ...(form.vatCountry && { vatCountry: form.vatCountry.name }),
          };
          stickerFacade.setActualBillingAddress({
            billingAddress: {
              ...form,
              ...varibles,
            },
          });
          closeDialog();
        });
      }
    };

    const closeDialog = () => {
      dialogCloseEmitter(ctx);
    };

    return {
      formValues,
      billingAddress,
      notLoggedInUser,
      modifyBillingAddressLoading,
      submit,
      closeDialog,
    };
  },
});
