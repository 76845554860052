










































































































import {
  defineComponent,
  computed,
  ref,
  reactive,
  watch,
  onMounted,
  SetupContext,
} from "@vue/composition-api";
import SwitchesInput from "@/components/forms/switch/SwitchesInput.vue";
import TextInput from "@/components/forms/input/TextInput.vue";
import CardSelector from "@/components/forms/CardSelector/CardSelector.vue";
import { userFacade } from "@/store/modules/user/user.facade";
import { stickerFacade } from "@/store/modules/sticker/sticker.facade";
import { languages } from "@/core/utils/languages";
import { CartElement } from "@/core/dataTypes/types";
import { EStickerType } from "@/core/dataTypes/enum";

export default defineComponent({
  name: "StickerPayEmailNotification",
  components: { SwitchesInput, TextInput, CardSelector },
  props: {
    loading: Boolean,
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setup(props, { refs }: Omit<SetupContext, "refs"> & { [key: string]: any }) {
    const user = computed(userFacade.user);
    const notLoggedInUser = computed(userFacade.notLoggedInUser);
    const cartElementList = computed(stickerFacade.cartElementList);
    const isShowEndEmailNotification = ref(true);

    const validityStartNotification = computed(
      stickerFacade.validityStartNotification
    );

    const validityEndNotification = computed(
      stickerFacade.validityEndNotification
    );

    const emailNotify = reactive({
      validityStartNotificationModelValue: false,
      validityEndNotificationModelValue: false,
    });
    emailNotify.validityStartNotificationModelValue =
      validityStartNotification.value as boolean;
    emailNotify.validityEndNotificationModelValue =
      validityEndNotification.value as boolean;

    watch(
      () => cartElementList.value as CartElement[],
      (cartElement: CartElement[]) => {
        isShowEndEmailNotification.value = cartElement.some(
          (sticker: CartElement) =>
            EStickerType.ONEDAY_N !== sticker.stickerType.id
        );
      }
    );
    watch(
      () => validityStartNotification.value,
      (value) => {
        emailNotify.validityStartNotificationModelValue = value as boolean;
      }
    );
    watch(
      () => validityEndNotification.value,
      (value) => {
        emailNotify.validityEndNotificationModelValue = value as boolean;
      }
    );

    const onChange = () => {
      stickerFacade.setEmailNotification({
        notifyValidityStartValue:
          emailNotify.validityStartNotificationModelValue as boolean,
        notifyValidityEndValue:
          emailNotify.validityEndNotificationModelValue as boolean,
      });
    };

    const cartAdhocExtraData = computed(stickerFacade.cartAdhocExtraData);
    const putAdhocExtraDataLoading = computed(
      stickerFacade.putAdhocExtraDataLoading
    );

    const watchDestroy = watch(
      () => cartAdhocExtraData.value,
      (cartAdhocExtraData) => {
        notifyData.email = (cartAdhocExtraData?.email as string) || "";
        notifyData.emailAgain = (cartAdhocExtraData?.email as string) || "";
        notifyData.contactLanguage =
          (cartAdhocExtraData?.contactLanguage as string) ||
          notifyData.contactLanguage;
        watchDestroy();
      }
    );
    const notifyData = reactive({
      email: "",
      emailAgain: "",
      contactLanguage: "HU",
    });

    const saveNotification = async () => {
      const isValid = await refs.form.validate();
      const isValidAdHocId = await stickerFacade.getAdHocValidityCheck(true);
      if (isValidAdHocId) {
        await stickerFacade.putAdhocExtraData({
          ...(isValid && { email: notifyData.email }),
          contactLanguage: notifyData.contactLanguage,
        });
      }
    };
    const onChangeLanguage = () => {
      // if (cartAdhocExtraDataIsValid.value) {}
      saveNotification();
    };
    const onChangeEmailInput = async () => {
      const isValid = await refs.form.validate();
      if (isValid) {
        saveNotification();
      } else {
        stickerFacade.resetAdhocExtraDataIsValid();
      }
    };

    onMounted(() => {
      if (notLoggedInUser.value) {
        refs.form.validate();
        stickerFacade.getAdhocExtraData();
      }
    });

    return {
      onChangeEmailInput,
      onChangeLanguage,
      onChange,
      saveNotification,
      user,
      languages,
      notifyData,
      cartAdhocExtraData,
      putAdhocExtraDataLoading,
      notLoggedInUser,
      validityStartNotification,
      validityEndNotification,
      emailNotify,
      isShowEndEmailNotification,
    };
  },
});
